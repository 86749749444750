import { ReactElement, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Autocomplete } from '@mui/material';
import styled from 'styled-components';
import { Input } from '../Fields/TextInput';

export const StyledAutocompleteTextField = styled(Input)`
  &&& .MuiInputBase-input {
    box-sizing: border-box; // Centers the input text in the autocomplete control.
  }
`;

export const StyledAutocompleteTextFieldMultiple = styled(Input)`
  &&& .MuiInputBase-input {
    box-sizing: border-box; // Centers the input text in the autocomplete control.
  }

  &&& .MuiInputBase-root {
    height: auto;
  }
`;

export type SuggestionsOption = { value: string; label: string; group?: string };

type Props = {
  label: string;
  disabled?: boolean;
  validationError?: { message: string } | undefined;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  options: SuggestionsOption[];
  required?: boolean;
};

export function Suggestions(props: Props): ReactElement {
  const onChange = useCallback(
    (event: any) => {
      // eslint-disable-next-line no-console
      const value = event.target.value;
      if (value === props.value) {
        return;
      }

      if (!value) {
        props.onChange(value || undefined);
      } else if (typeof value === 'string') {
        props.onChange(value);
      } else {
        props.onChange(value.value);
      }
    },
    [props],
  );

  return (
    <Autocomplete
      multiple={false}
      freeSolo={true}
      disabled={props.disabled}
      options={props.options}
      value={props.value}
      autoSelect={true}
      onBlur={onChange}
      aria-required={props.required}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      groupBy={(e) => {
        if (typeof e === 'string') {
          return ' ';
        } else {
          return e.group || '';
        }
      }}
      renderInput={(inputProps) => (
        <StyledAutocompleteTextFieldMultiple
          {...inputProps}
          label={props.label}
          inputVariant='outlined'
          error={!!props.validationError}
          helperText={props.validationError?.message}
        />
      )}
      renderGroup={(params) => (
        <div key={params.key}>
          <div>{params.group}</div>
          <div>{params.children}</div>
        </div>
      )}
      getOptionKey={(option) => {
        if (typeof option === 'string') {
          return option;
        } else {
          return option.group + option.value;
        }
      }}
    />
  );
}
