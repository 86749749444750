import { ComponentType, ReactElement } from 'react';

import { InfoVariants } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { getHtmlAttributesForImageType, ImageTypes } from '../Icon/accessibilityIcons.helper';
import {
  BorderStyle,
  ConfirmationIcon,
  Container,
  ErrorIcon,
  IconContainer,
  IconVerticalAlignment,
  InformationIcon,
  WarningIcon,
  TextContainer,
} from './Styles';
import { ReactHtmlParser } from '../ReactHtmlParser';

type InfoVariantIconMap = Omit<Record<keyof typeof InfoVariants, ComponentType>, 'paragraph'>;

export type InformationProps = {
  title?: string;
  text: string;
  locale?: Language;
  iconMap?: InfoVariantIconMap;
  variant?: InfoVariants;
  borderStyle?: BorderStyle;
  iconVerticalAlignment?: IconVerticalAlignment;
  className?: string;
  imageType?: ImageTypes;
  boldedBorder?: boolean;
};

const IconMap: InfoVariantIconMap = {
  [InfoVariants.error]: ErrorIcon,
  [InfoVariants.info]: InformationIcon,
  [InfoVariants.success]: ConfirmationIcon,
  [InfoVariants.warning]: WarningIcon,
};

export function Information(props: InformationProps): ReactElement {
  let Icon: ComponentType | undefined;
  if (props.variant) {
    if (props.variant === 'paragraph') {
      Icon = undefined;
    } else {
      Icon = props.iconMap?.[props.variant] ?? IconMap[props.variant];
    }
  }

  // Can assume that the information cards will always be decorative.
  const decorativeImageTypeDefaultAttributes = getHtmlAttributesForImageType({
    imageType: ImageTypes.decorative,
  });

  return (
    <Container
      className={props.className}
      variant={props.variant}
      borderStyle={props.borderStyle ?? 'straight'}
      boldedBorder={props.boldedBorder}
    >
      {Icon ? (
        <IconContainer $verticalAlignment={props.iconVerticalAlignment ?? 'top'}>
          <Icon {...decorativeImageTypeDefaultAttributes} />
        </IconContainer>
      ) : null}
      <div>
        {props.title && (
          <TextContainer>
            <strong>
              <ReactHtmlParser html={props.title} />
            </strong>
          </TextContainer>
        )}
        <TextContainer>
          <ReactHtmlParser html={props.text} />
        </TextContainer>
      </div>
    </Container>
  );
}
