import { Box, FormHelperText, InputAdornment, TextFieldProps, Tooltip } from '@breathelife/mui';
import _ from 'lodash';
import { Component, FocusEvent, ReactElement, useState } from 'react';

import { FieldSymbol, ValidationError } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { Container, SubtitleText, TitleText, WhiteSpaceContainer } from './Styles/CommonStyles';
import { StyledFormControlLabel, StyledFormHelperText, StyledTextField } from './Styles/FieldStyles';
import { ReactHtmlParser } from '../ReactHtmlParser';

type Props<T extends string | number> = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    symbol?: FieldSymbol;
    locale?: Language;
    value?: T;
    onAnswerChange?: (answer: T) => void;
    onAnswerComplete?: (fieldId: string, answer: T, previousAnswer?: T) => void;
    inputVariant?: InputVariant;
    allowTextWrap?: boolean;
    boldedBorder?: boolean;
  };

type State = {
  value?: string | number;
  disablePropsValueUpdate: boolean;
};

export class TextInput<T extends string | number> extends Component<Props<T>, State> {
  state = {
    value: this.props.value,
    disablePropsValueUpdate: false,
  };

  componentDidUpdate(previousProps: any): void {
    if (previousProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  debouncedParentOnAnswerChange = (): void => {
    if (this.props.onAnswerChange) {
      this.setState({ disablePropsValueUpdate: true });
      _.debounce(() => {
        this.setState({ disablePropsValueUpdate: false });
      }, 200);
    }
  };

  onAnswerChange = (answer: string | number): void => {
    this.setState({ value: answer }, () => {
      this.debouncedParentOnAnswerChange();
    });
  };

  onBlur = (): void => {
    if (!this.props.onAnswerChange || typeof this.state.value === 'undefined') return;

    const trimmedValue = typeof this.state.value === 'string' ? (this.state.value.trim() as T) : this.state.value;

    this.setState({ value: trimmedValue }, () => {
      if (this.props.onAnswerChange) this.props.onAnswerChange(trimmedValue);
    });
  };

  render(): ReactElement {
    const inputProps: any = _.cloneDeep(this.props);

    if (this.props.symbol) {
      const adornment = `${_.get(this.props, ['symbol', 'position'], 'start')}Adornment`;
      inputProps[adornment] = (
        <InputAdornment position={this.props.symbol.position || 'start'}>{this.props.symbol.name}</InputAdornment>
      );
    }

    inputProps.onAnswerChange = this.onAnswerChange;

    // Read only means there is no local state to handle. Always use the value coming from props
    if (!inputProps.isReadOnly && this.state.disablePropsValueUpdate) {
      inputProps.value = this.state.value;
    }

    return <TextFieldMUI onBlur={this.onBlur} {...inputProps} />;
  }
}

export function TextFieldMUI<T extends string | number>(props: Props<T>): ReactElement {
  const {
    id,
    name,
    type,
    value,
    label,
    title,
    disabled = false,
    placeholder,
    validationError,
    onAnswerChange,
    onAnswerComplete,
    onBlur,
    onChange,
    onKeyDown,
    InputLabelProps,
    inputVariant,
    inputRef,
    subtitle,
    allowTextWrap,
    multiline = false,
    minRows = 1,
    maxRows = 1,
    isReadOnly,
    required,
    optionalText,
    boldedBorder,
  } = props;
  const showError = !!validationError;
  const onChangeHandler =
    onChange ||
    ((event: any) => {
      onAnswerChange && onAnswerChange(event.target.value);
    });

  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState(value);
  const textFieldProps = {
    id,
    name,
    type,
    disabled,
    placeholder,
    error: showError,
    value,
    InputLabelProps,
    label,
    title,
    subtitle,
    inputRef,
    'data-testid': props['data-testid'],
    onChange: onChangeHandler,
    onBlur: (event: FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event);

      if (typeof value !== 'undefined') {
        onAnswerComplete && onAnswerComplete(name, value, valueOnPreviousBlur);
        setValueOnPreviousBlur(value);
      }
    },
    onKeyDown,
    multiline,
    minRows,
    maxRows,
    allowTextWrap,
    isReadOnly,
    required,
    optionalText,
    boldedBorder,
  };

  return <Input {...textFieldProps} inputVariant={inputVariant} validationError={validationError} />;
}

export function Input(
  props: Omit<TextFieldProps, 'variant'> & {
    boldedBorder?: boolean;
    highlighted?: boolean;
    inputVariant?: InputVariant;
    validationError?: ValidationError;
    labelTooltip?: {
      icon: ReactElement;
      text: string;
    };
    inputTooltip?: {
      icon: ReactElement;
      text: string;
    };
    allowTextWrap?: boolean;
    title?: string;
    subtitle?: string;
    isReadOnly?: boolean;
    optionalText?: string;
  },
): ReactElement {
  const {
    allowTextWrap,
    inputTooltip,
    inputVariant,
    isReadOnly,
    label,
    labelTooltip,
    optionalText,
    placeholder,
    required,
    subtitle,
    title,
    validationError,
    ...textFieldProps
  } = props;

  const labelValue = formatRequiredFieldTitle(required, title, label as string, optionalText);
  const error = textFieldProps.error ?? !!validationError;
  const wrapText = allowTextWrap ?? false;

  if (inputVariant === 'outlined') {
    const controlInput = inputTooltip ? (
      <Box display='flex' alignItems='center'>
        <StyledTextField
          {...textFieldProps}
          inputProps={{
            ...textFieldProps.inputProps,
            required,
            'aria-required': required,
          }}
          error={!!error}
          placeholder={placeholder}
          variant={inputVariant}
        />
        <Tooltip title={inputTooltip.text} placement='right' arrow>
          <Box lineHeight='0' ml={1}>
            {inputTooltip.icon}
          </Box>
        </Tooltip>
      </Box>
    ) : (
      <StyledTextField
        {...textFieldProps}
        inputProps={{
          ...textFieldProps.inputProps,
          required,
          'aria-required': required,
        }}
        error={!!error}
        placeholder={placeholder}
        variant={inputVariant}
      />
    );

    return (
      <Container variant='standard' error={!!error}>
        <StyledFormControlLabel
          showError={!!error}
          control={controlInput}
          label={
            <WhiteSpaceContainer $wrapContent={wrapText}>
              <Box display='flex' alignItems='center'>
                {/* Read only field title should always appears as not disabled even if the field input is disabled*/}
                {isReadOnly ? (
                  <TitleText>
                    <ReactHtmlParser html={labelValue} />
                  </TitleText>
                ) : (
                  <span>
                    <ReactHtmlParser html={labelValue} />{' '}
                  </span>
                )}
                {labelTooltip && (
                  <Tooltip title=<ReactHtmlParser html={labelTooltip.text} /> placement='right' arrow>
                    <Box lineHeight='0' ml={1}>
                      {labelTooltip.icon}
                    </Box>
                  </Tooltip>
                )}
              </Box>
              {subtitle ? (
                <SubtitleText>
                  <ReactHtmlParser html={subtitle} />
                </SubtitleText>
              ) : null}
            </WhiteSpaceContainer>
          }
          labelPlacement='top'
        />
        {validationError && <StyledFormHelperText>{validationError.message}</StyledFormHelperText>}
      </Container>
    );
  }

  return (
    <Container variant='standard' error={!!error}>
      <StyledTextField
        {...textFieldProps}
        inputProps={{
          ...textFieldProps.inputProps,
          required,
          'aria-required': required,
        }}
        label={
          <WhiteSpaceContainer $wrapContent={wrapText}>
            <ReactHtmlParser html={labelValue} />
          </WhiteSpaceContainer>
        }
        variant='standard'
      />
      {validationError && <FormHelperText>{validationError.message}</FormHelperText>}
    </Container>
  );
}
